<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group
        label-size="lg"
        label-cols-md="5"
        label-cols-lg="4"
        class="mb-0"
      >
        <template #label>
          Taxa Mínima de Atratividade - TMA <span class="text-danger">*</span>
        </template>
        <b-input-group append="%">
          <KTInputNumeric
            v-model="form.tma"
            :precision="2"
            :min="0"
            :max="100"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </b-input-group>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipFluxoCaixaForm",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-tachometer-alt",
        title: "Taxa Mínima de Atratividade (TMA)",
        description: `A Taxa Mínima de Atratividade (TMA) é considerada
          uma taxa de referência quando se trata de investimentos financeiros.
          Conhecida também como taxa de desconto ou custo de oportunidade,
          pode ser entendida como o retorno mínimo que o investimento deverá
          produzir para ser atraente. A TMA é comumente empregada para descontar
          o fluxo de caixa, ou seja, trazer a valor presente, deste modo torna-se
          fundamental para estimar parâmetros como o Valor Presente Líquido (VPL).
          Insira a seguir a TMA para o seu projeto. Para ampliar a reflexão e
          assegurar a melhor tomada de decisão, a PAEF indicará uma TMA baseada
          no potencial de risco do projeto.`
      },
      form: {},
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/fluxo_caixa`;
    },
    manual() {
      return {
        session: "fluxo_caixa",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_fluxo_caixa_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData()];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].taxa ?? {};
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmitAdd(this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
